import { TextField, styled } from "@mui/material";
import theme from "../../../utils/theme";

const TextInputStyle = styled(TextField)(() => ({
  label: {
    "&.Mui-focused": {
      color: `${theme.palette.secondary.dark} !important`
    }
  },
  input: {
    borderRadius: 0,
    padding: "12.5px 14px",
    height: "1.8500rem"
  },
  fieldset: {
    borderColor: `${theme.palette.secondary.dark} !important`
  }
}));

export default TextInputStyle;
