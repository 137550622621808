import { ethers } from "ethers";
import { OfferModel, PhysicalItemVariant } from "../models/offer";

export const createContractInstance = (addr: string, abi: any, providerOrSigner: any) => {
  return new ethers.Contract(addr, abi, providerOrSigner);
};

export const getRandomNumber = (): number => {
  return Math.floor(Math.random() * 999999999999);
};

export const hasPhysicalItem = (offer: OfferModel): boolean => {
  const propertiesHavePhysicalItem = offer.properties?.some((property) => property.physicalItem);
  if (propertiesHavePhysicalItem) return true;
  const bonusesHavePhysicalItem = offer.features?.some((bonus) => bonus.physicalItem);
  if (bonusesHavePhysicalItem) return true;
  return false;
};

export const getQuantityByOffer = (offer: OfferModel): number => {
  if (offer.quantity) return offer.quantity.value;
  return 1;
};

export const hasPhysicalItemSize = (offer: OfferModel): boolean => {
  const propertiesHavePhysicalItemSize = offer.properties!.some(
    (property) => property.physicalItem?.sizes
  );
  const bonusesHavePhysicalItemSize = offer.features!.some((bonus) => bonus.physicalItem?.sizes);
  return propertiesHavePhysicalItemSize || bonusesHavePhysicalItemSize;
};

export const getAllShoesSKU = (offer: OfferModel): PhysicalItemVariant => {
  const results: PhysicalItemVariant = {
    shoesSKU: [],
    othersSKU: [],
  };
  offer.properties
    ?.filter((property) => property.physicalItem)
    .forEach((property) => {
      Object.keys(property.physicalItem?.selectedSizes!).forEach((key) => {
        const sku = property.physicalItem?.selectedSizes![key]!;
        if (property.physicalItem?.sizes) {
          results.shoesSKU.push(sku);
        } else {
          results.othersSKU.push(sku);
        }
      });
    });
  offer.features
    ?.filter((bonus) => bonus.physicalItem)
    .forEach((bonus) => {
      Object.keys(bonus.physicalItem?.selectedSizes!).forEach((key) => {
        const sku = bonus.physicalItem?.selectedSizes![key]!;
        if (bonus.physicalItem?.sizes) {
          results.shoesSKU.push(sku);
        } else {
          results.othersSKU.push(sku);
        }
      });
    });
  return results;
};

export const componentTracking = (eventName: string, eventParams: { [key: string]: string }) => {
  window.gtag("event", eventName, eventParams);
};

export const isOnlyBuyWithCrypto = (offer: OfferModel): boolean =>
  offer.creditCardLimitation !== undefined &&
  offer.remaining !== undefined &&
  offer.remaining <= offer.creditCardLimitation;
