import { Autocomplete } from "@mui/material";
import { styled } from "@mui/system";
import theme from "../../../utils/theme";

const StateInputStyle = styled(Autocomplete)(() => ({
  label: {
    "&.Mui-focused": {
      color: `${theme.palette.secondary.dark} !important`
    }
  },
  ".MuiInputBase-root": {
    padding: "5px",
    input: {
      borderRadius: 0,
      padding: "12.5px 14px",
      height: "1.8500rem"
    },
    fieldset: {
      borderColor: `${theme.palette.secondary.dark} !important`
    }
  }
}));

export default StateInputStyle;
