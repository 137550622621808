import { useCallback, useEffect, useState } from "react";
import { BillingAddress, ShippingAddress } from "../contexts/Store";
import { api } from "../utils/api";
import { toast } from "react-toast";
import useCountryService from "./Country";
import TotalPriceModel from "../models/totalPrice";
import PaymentServerRequestModel from "../models/requests/paymentServer";
import { useStoreContext } from "../hooks/Contexts";
import { useRecaptchaVerify } from "../hooks/Util";
import { collectTrumpCardsSite, paymentServer } from "../utils/constants";
import { getAllShoesSKU, isOnlyBuyWithCrypto } from "../utils/utils";
import useCreditCardService from "./CreditCard";
import axios, { AxiosError } from "axios";
import { OFFERS } from "../components/stepper/offers/OffersData";
import useCounterService from "./Counter";

const PAYMENT_RESPONSE_IS_SUCCESS = "1";

export interface CollectJsInitializeParams {
  billing: BillingAddress;
  shipping: ShippingAddress;
  price: TotalPriceModel;
  walletAddress: string;
}

const useCollectJSService = () => {
  const countryService = useCountryService();
  const recaptchaVerify = useRecaptchaVerify();
  const [validateFields, setValidateFields] = useState({
    ccnumber: null,
    ccexp: null,
    cvv: null,
  });
  const [collectJsInitializeParams, setCollectJsInitializeParams] =
    useState<CollectJsInitializeParams>({} as CollectJsInitializeParams);
  const { store, setStore, setTransactionInProgress, setThreeDSInProgress } = useStoreContext();
  const [reInit, setReInit] = useState(0);
  const init = useCallback(
    (params: CollectJsInitializeParams) => {
      setCollectJsInitializeParams(params);
    },
    [reInit]
  );
  const { getTotalPrice, getShippingPrice } = useCreditCardService();
  const { updateOffersWithRemaining } = useCounterService();

  const unmountThreeDS = () => {
    //@ts-ignore
    if (typeof GatewayJSThreeDSecureMountedUISingleton != "undefined") {
      //@ts-ignore
      GatewayJSThreeDSecureMountedUISingleton.unregister();
      //@ts-ignore
      document.getElementById("threeDSMountPoint").innerHTML = "";
    }
  };
  const validationCallbackHandler = (field: string, status: boolean, message: string): void => {
    setValidateFields((prevState) => ({
      ...prevState,
      [field]: status,
    }));
    if (!status) {
      setTransactionInProgress(false);
      toast.error(message);
    }
  };

  useEffect(() => {
    if (Object.keys(collectJsInitializeParams).length === 0) return;
    //@ts-ignore
    window.CollectJS.configure({
      variant: "inline",
      styleSniffer: true,
      validationCallback: validationCallbackHandler,
      callback: callbackHandler,
      fields: {
        ccnumber: {
          placeholder: "Card Number",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "Expiry",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "CVV",
          selector: "#cvv",
        },
      },
    });
  }, [collectJsInitializeParams, reInit]);

  const callbackHandler = useCallback(
    (callbackResponse: { token: string }) => {
      try {
        unmountThreeDS();
        if (store.cart.threeDSInProgress) {
          console.warn("Another 3DS instance is already in progress.");
          return;
        }

        const params = collectJsInitializeParams;
        const country = countryService.getCodeByName(params.billing.country) ?? "";
        const shippingCountry = countryService.getCodeByName(params.shipping.country) ?? "";
        const optionsForGateway = {
          paymentToken: callbackResponse.token,
          amount: getTotalPrice().toString(),
          email: params.billing.email,
          city: params.billing.city,
          address1: params.billing.street,
          country,
          state: params.billing.state,
          firstName: params.billing.firstName,
          lastName: params.billing.lastName,
          postalCode: params.billing.postalCode,
          currency: "USD",
        };

        //@ts-ignore
        if (!window.threeDS) {
          console.error("3D Secure interface is not available");
          return;
        }

        const mountPoint = document.getElementById("threeDSMountPoint");
        if (!mountPoint) {
          console.error("#threeDSMountPoint element is not found");
          return;
        }

        const threeDSecureInterface =
          //@ts-ignore
          window.threeDS.createUI(optionsForGateway);
        setThreeDSInProgress(true);
        threeDSecureInterface.start("#threeDSMountPoint");

        threeDSecureInterface.on("error", function (e: any) {
          toast.error(e.message);
          setTransactionInProgress(false);
          setThreeDSInProgress(false);
        });

        threeDSecureInterface.on("complete", function (e: any) {
          try {
            const optionsToSend = {
              ...optionsForGateway,
              tax: params.price.taxPrice.toString(),
              walletAddress: params.walletAddress,
              fulfillmentFee: params.price.serviceFee.toString(),
              shipping_firstname: params.shipping.firstName,
              shipping_lastname: params.shipping.lastName,
              shipping_address1: params.shipping.street,
              shipping_address2: params.shipping.apartment,
              shipping_city: params.shipping.city,
              shipping_state: params.shipping.state,
              shipping_zip: params.shipping.postalCode,
              shipping_phone: params.shipping.phone,
              shipping_country: shippingCountry,
              cavv: e.cavv,
              xid: e.xid,
              eci: e.eci,
              cardHolderAuth: e.cardHolderAuth,
              threeDsVersion: e.threeDsVersion,
              directoryServerId: e.directoryServerId,
              cardHolderInfo: e.cardHolderInfo,
              sneakers: JSON.stringify(getAllShoesSKU(store.cart.offer!).shoesSKU),
              packageType: store.cart.offer!.displayType,
              numberOfTokens: store.cart.offer!.numberOfTokens,
              shippingPrice: getShippingPrice(),
            };
            paySubmit(optionsToSend);
          } catch (err: any) {
            console.error("Error during complete handling:", err);
            setThreeDSInProgress(false);
            unmountThreeDS();
            toast.error(err.message);
          }
        });

        threeDSecureInterface.on("failure", function (e: any) {
          setReInit(reInit + 1);
          setThreeDSInProgress(false);
          setTransactionInProgress(false);
          setValidateFields({
            ccnumber: null,
            ccexp: null,
            cvv: null,
          });
          unmountThreeDS();
          toast.error(e?.message);
        });
      } catch (err: any) {
        console.error("Error during 3DS initialization:", err);
        setThreeDSInProgress(false);
        unmountThreeDS();
        toast.error(err.message);
      }
    },
    [collectJsInitializeParams, countryService, store.cart.threeDSInProgress]
  );

  const paySubmit = async (request: PaymentServerRequestModel): Promise<void> => {
    let errorMessage: string = "";
    try {
      const updatedPackages = await updateOffersWithRemaining(OFFERS);
      const existsPackage = updatedPackages.find(
        (p) => p.packageType === store.cart.offer!.packageType
      );
      const remaining = existsPackage?.remaining;
      if (remaining !== undefined && remaining <= 0) {
        toast.error(
          "Unfortunately the selected package has sold out. Please select a different package!"
        );
        resetInit();
        return;
      }
      if (existsPackage && isOnlyBuyWithCrypto(existsPackage)) {
        toast.error(
          "Unfortunately the selected package is not available for Credit Card buyers anymore!"
        );
        resetInit();
        return;
      }
      const recaptchaToken = await recaptchaVerify();
      const response = await api.post(paymentServer, {
        ...request,
        recaptchaToken: recaptchaToken,
      });
      if (response.data.response !== PAYMENT_RESPONSE_IS_SUCCESS) {
        errorMessage = response.data.responsetext;
        toast.error(errorMessage);
        setReInit(reInit + 1);
        setThreeDSInProgress(false);
        setTransactionInProgress(false);
        setValidateFields({
          ccnumber: null,
          ccexp: null,
          cvv: null,
        });
        unmountThreeDS();
        throw new Error(String(response.status));
      }
      window.onbeforeunload = null;
      window.location.href = `${collectTrumpCardsSite}/thank-you?nftAmount=${store.cart.offer?.numberOfTokens}`;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 429) {
          // Display toast error for 429 status code
          toast.error("Too many requests. Please try again later.");
        }
      }
      window.onbeforeunload = null;
      setTransactionInProgress(false);
      setStore((prevState) => ({
        ...prevState,
        cart: {
          ...prevState.cart,
          creditCardTransactionErrorMessage: errorMessage,
        },
      }));
    }
  };

  const resetInit = (): void => {
    setReInit(reInit + 1);
    setThreeDSInProgress(false);
    setTransactionInProgress(false);
    setValidateFields({
      ccnumber: null,
      ccexp: null,
      cvv: null,
    });
    unmountThreeDS();
  };

  return {
    init,
    validateFields,
  };
};

export default useCollectJSService;
