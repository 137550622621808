import { styled, Box } from "@mui/material";

const QuantitySelectorStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color12.main,
  padding: "12px 0",
  bottom: "0",
  position: "sticky",
  width: "100%",
  left: 0,
  ".product-selector-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  ".product-selector-info-box": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    fontfFamily: "SF UI Display Semibold",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      fontSize: "12px",
      "svg, .your-cart": {
        display: "none",
      },
    },
  },
  ".cart-remove-btn": {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    textTransform: "capitalize",
    fontWeight: "600",
    backgroundColor: theme.palette.color21.main,
    color: theme.palette.color22.main,
  },
}));

export default QuantitySelectorStyle;
