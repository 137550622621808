import styled from '@emotion/styled';
import { Box } from '@mui/material';
import theme from '../../../utils/theme';

const GetStartedStyle = styled(Box)(() => ({
  h1: {
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
    padding: `${theme.spacing(4)} 0px`,
  },
  ".either": {
    padding: `${theme.spacing(4)} 0px`,
  },
  '.py-3': {
    padding: `${theme.spacing(3)} 0`,
  },
  '.mt-3': {
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down("xxs")]: {
    h1: {
      fontSize: "29px"
    },
    "> .MuiGrid-root": {
      "> div": {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      }
    }
  }
}));

export default GetStartedStyle;
