import styled from "@emotion/styled";
import { Box } from "@mui/material";
import theme from "../../../utils/theme";

const PaymentMethodsStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(3)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  ".agree-to-the-terms": {
    padding: `${theme.spacing(3)} 0px`,
    textAlign: "center",
    [theme.breakpoints.down("xxs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      lineHeight: "21px",
    },
  },
  ".payment-method-buttons": {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("xxs")]: {
      paddingTop: theme.spacing(1),
    },
    ".MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
      ".button-title-and-icon": {
        color: theme.palette.color1.main,
      },
    },
    ".Mui-disabled": {
      border: `1px solid ${theme.palette.secondary.dark} !important`,
    },
    ".description": {
      textAlign: "center",
      marginTop: "8px",
      lineHeight: "20px",
    },
  },
  ".credit-or-crypto-container": {
    margin: `${theme.spacing(4)} 0`,
    [theme.breakpoints.down("xxs")]: {
      margin: `${theme.spacing(2)} 0`,
    },
  },
  ".terms-and-conditions": {
    display: "flex",
    marginBottom: theme.spacing(2),
    padding: "10px 10px 10px 0px",
    ".disclaimer-text": {
      padding: theme.spacing(1),
      margin: "2px 0",
      display: "flex",
      gap: theme.spacing(1),
    },
  },
  ".terms-conditions-text": {
    fontSize: "11px",
    lineHeight: "11px",
    span: {
      fontSize: "11px",
      lineHeight: "11px",
    },
  },
  ".button-title-and-icon": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export default PaymentMethodsStyle;
