import { Box, styled } from '@mui/material';
import theme from '../../../utils/theme';

const HeaderStyle = styled(Box)(() => ({
  backgroundColor: theme.palette.secondary.light,
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  height: '72px',
  color: theme.palette.color1.main,
  position: "fixed",
  width: "100%",
  zIndex: 100002,
  '.MuiContainer-root, .MuiGrid-container, .MuiGrid-item': {
    height: '100%',
    '.back-section, .step, .close-section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    button: {
      color: theme.palette.color1.main,
      textTransform: 'capitalize',
    },
    '.back-section': {
      alignItems: 'flex-start',
      [theme.breakpoints.down('xxs')]: {
        alignItems: 'flex-end',
      },
    },
    '.steps-section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '.MuiBadge-root': {
        top: '-1px',
      },
      '.MuiBadge-badge': {
        backgroundColor: theme.palette.color1.dark,
        color: theme.palette.secondary.dark,
      },
      '.title': {
        marginLeft: theme.spacing(2),
        color: theme.palette.color1.dark,
        '@media (max-width:600px)': {
          fontSize: '11px',
          lineHeight: '14px',
        },
      },
      '.active': {
        '.MuiBadge-badge': {
          backgroundColor: theme.palette.color5.main,
        },
        '.title': {
          color: theme.palette.color5.main,
        },
      },
    },
    '.close-section': {
      alignItems: 'flex-end',
    },
  },
}));

export default HeaderStyle;
