import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const useRecaptchaVerify = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyCaptcha = async () => {
    let recaptchaToken;
    if (!executeRecaptcha) {
      return "!executeRecaptcha";
    }
    try {
      const token = await executeRecaptcha("yourAction");
      recaptchaToken = token;
    } catch (error) {
      console.error(error);
      recaptchaToken = "";
    }
    return recaptchaToken;
  };

  return verifyCaptcha;
};
