import { ethers } from "ethers";
import { useCallback, useEffect } from "react";
import { toast } from "react-toast";
import { ContainerMaxWidth } from "../../../contexts/Store";
import { useStoreContext } from "../../../hooks/Contexts";
import useContractService from "../../../services/Contract";
import { maxMintableTokens } from "../../../utils/constants";
import { componentTracking } from "../../../utils/utils";

const SignIn = () => {
  const { store, setStore } = useStoreContext();
  const { getDiamondMintRecords, getTokenBalanceOfByWalletAddress } = useContractService();

  const redirectHandler = async (
    provider: ethers.BrowserProvider,
    wallet: string
  ): Promise<void> => {
    const tokenBalance = await getTokenBalanceOfByWalletAddress(wallet, provider);
    if (tokenBalance === maxMintableTokens) {
      setStore((prevState) => ({
        ...prevState,
        flowControl: {
          ...prevState.flowControl,
          activeStep: {
            ...prevState.flowControl.steps.reachedMaxMinted,
            title: "Checkout",
          },
        },
        containerMaxWidth: ContainerMaxWidth.DEFAULT,
      }));
      return;
    }

    const mintRecords = await getDiamondMintRecords(provider, wallet);
    if (mintRecords) {
      const minted = mintRecords.minted;
      const start = mintRecords.start;
      if (start > 0 && minted < maxMintableTokens) {
        setStore((prevState) => ({
          ...prevState,
          flowControl: {
            ...prevState.flowControl,
            activeStep: {
              ...prevState.flowControl.steps.diamondMinter,
              title: "Checkout",
            },
          },
          containerMaxWidth: ContainerMaxWidth.DEFAULT,
        }));
        return;
      }
      if (minted === maxMintableTokens) {
        setStore((prevState) => ({
          ...prevState,
          flowControl: {
            ...prevState.flowControl,
            activeStep: {
              ...prevState.flowControl.steps.reachedMaxMinted,
              title: "Checkout",
            },
          },
          containerMaxWidth: ContainerMaxWidth.DEFAULT,
        }));
        return;
      }
    }
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          ...prevState.flowControl.steps.paymentMethods,
          title: "Checkout",
        },
      },
    }));
  };

  useEffect(() => {
    componentTracking("component_interaction", {
      checkout_view: "Sign In",
    });

    const updateHeader = () => {
      const element = document.querySelector(".w3a-header__subtitle");
      if (!element) {
        setTimeout(updateHeader, 100);
        return;
      }
      element.innerHTML = "Select one of the following to continue.";
    };

    const timeout = setTimeout(updateHeader, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const afterConnected = async (web3AuthProvider: ethers.Eip1193Provider) => {
    const provider = new ethers.BrowserProvider(web3AuthProvider);
    const userInfo = await store?.web3Auth?.web3AuthInstance?.getUserInfo();
    const accounts = await provider.listAccounts();
    setStore((prevState: any) => ({
      ...prevState,
      web3Auth: {
        ...prevState.web3Auth,
        web3AuthProvider: provider,
      },
      cart: {
        ...prevState.cart,
        user: {
          wallet: accounts[0]?.address,
          email: userInfo ? userInfo.email : null,
        },
      },
    }));

    // We need to wait until the web3Auth modal hides its successful connection screen
    if (userInfo && userInfo.email) {
      await redirectHandler(provider, accounts[0]?.address);
    } else {
      const waitForWeb3authModal = setTimeout(async () => {
        await redirectHandler(provider, accounts[0]?.address);
      }, 3000);
      return () => {
        clearTimeout(waitForWeb3authModal);
      };
    }
  };

  const checkConnection = useCallback(async () => {
    try {
      localStorage.setItem("signIn", "OK");
      const web3AuthProvider = await store.web3Auth.web3AuthInstance?.connect();
      afterConnected(web3AuthProvider as ethers.Eip1193Provider);
    } catch (e) {
      console.error(e);
      // @ts-ignore
      if (e.code === -32602) {
        toast.error("Please change the network to Polygon");
      }
      setTimeout(() => {
        checkConnection();
      }, 3000);
    }
  }, [store.web3Auth.web3AuthInstance]);

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          title: "Get Started",
          component: prevState.flowControl.steps.getStarted.component,
        },
      },
    }));
    if (!store.web3Auth.isInitialized) return;
    checkConnection();
  }, [store.web3Auth.isInitialized, checkConnection]);

  return <></>;
};

export default SignIn;
