import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";

import ButtonBlue from "../../form/button-blue/Component";
import CreditCardPaymentStyle from "./Style";
import theme from "../../../utils/theme";
import { useStoreContext } from "../../../hooks/Contexts";
import useCollectJSService from "../../../services/CollectJS";
import { ActiveStepModel, ContainerMaxWidth } from "../../../contexts/Store";
import { SafeAndSecureIcon } from "../../../utils/svgIcon";
import useCreditCardService from "../../../services/CreditCard";
import TotalPriceModel from "../../../models/totalPrice";
import { toast } from "react-toast";
import TransactionLoader from "../../transaction-loader/Component";
import { componentTracking, hasPhysicalItem, hasPhysicalItemSize } from "../../../utils/utils";
import OrderSummary from "../../order-summary/Component";

const CreditCardPayment = () => {
  const threeDSMountPointRef = useRef(null);
  const { init, validateFields } = useCollectJSService();

  const payButton = useRef(null);
  const creditCardService = useCreditCardService();
  const { store, setStore, setTransactionInProgress, setThreeDSInProgress } = useStoreContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [price, setPrice] = useState<TotalPriceModel>({
    originalPrice: 0,
    taxPrice: 0,
    serviceFee: 0,
    totalPrice: 0,
    shippingPrice: 0,
  });
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  useEffect(() => {
    setPrice({
      originalPrice: creditCardService.getOriginalPrice(),
      taxPrice: creditCardService.getTaxPrice(),
      serviceFee: creditCardService.getFeePrice(),
      totalPrice: creditCardService.getTotalPrice(),
      shippingPrice: creditCardService.getShippingPrice(),
    });
  }, [store.cart.offer, store.cart.addresses.billing.postalCode]);

  const initPayment = useCallback((): (() => void) => {
    init({
      billing: store.cart.addresses.billing,
      shipping: store.cart.addresses.shipping,
      price,
      walletAddress: store.cart.user.wallet,
    });
    const time = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(time);
    };
  }, [price]);

  useEffect(() => {
    if (price.originalPrice > 0) {
      initPayment();
    }
  }, [price.originalPrice, initPayment]);

  const creditCardBackStepByQuantity = (): ActiveStepModel => {
    if (store.cart.offer && hasPhysicalItem(store.cart.offer)) {
      if (hasPhysicalItemSize(store.cart.offer)) {
        return {
          component: store.flowControl.steps.physicalItemSizes.component,
          title: "Checkout",
        };
      }
      return {
        component: store.flowControl.steps.shippingAddressForm.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.billingAddressForm.component,
      title: "Checkout",
    };
  };

  const boxStyle = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
  };

  const cryptoBackStepByQuantity = (): ActiveStepModel => {
    if (store.cart.offer && hasPhysicalItem(store.cart.offer)) {
      if (hasPhysicalItemSize(store.cart.offer)) {
        return {
          component: store.flowControl.steps.physicalItemSizes.component,
          title: "Checkout",
        };
      }
      return {
        component: store.flowControl.steps.shippingAddressForm.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.kycForm.component,
      title: "Checkout",
    };
  };

  const backHandler = (): ActiveStepModel => {
    if (store.cart.paymentMethod === "CreditCard") {
      return creditCardBackStepByQuantity();
    }

    return cryptoBackStepByQuantity();
  };

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: backHandler(),
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    const allFieldsValid = Object.values(validateFields).every((value) => value === true);
    setDisabledButton(!allFieldsValid);
  }, [validateFields]);

  const onSubmit = (e: any) => {
    componentTracking("checkout", {
      checkout_click: "Pay with " + store.cart.paymentMethod,
    });
    //@ts-ignore
    payButton?.current?.focus();

    e.preventDefault();
    window.onbeforeunload = function () {
      return "Transaction in progress. Please don't close the window!";
    };
    if (
      validateFields.ccnumber !== true ||
      validateFields.ccexp !== true ||
      validateFields.cvv !== true
    ) {
      toast.error("Fill the payment form correctly!");
    } else {
      componentTracking("checkout", {
        checkout_click: "Paying is in progress with " + store.cart.paymentMethod,
      });
      setTransactionInProgress(false);
      setThreeDSInProgress(true);
      //@ts-ignore
      window.CollectJS.startPaymentRequest();
    }
  };

  const priceRow = (label: string, price: number | undefined) => {
    return (
      <Grid container className="prices">
        <Grid item>
          <Typography variant="textMediumNormal">{label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="textMediumNormal">$ {price?.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CreditCardPaymentStyle>
        <Typography variant="h1">Enter Payment Details</Typography>
        <Box
          className="payment-container"
          sx={{ display: store.cart.threeDSInProgress ? "none" : "block" }}
        >
          <Typography
            variant="textMediumSemibold"
            component="p"
            textAlign="center"
            className="description"
          >
            All transactions are secure and encrypted.
          </Typography>
          <OrderSummary />
          <Typography
            variant="h6"
            component="p"
            sx={{
              padding: {
                xxxs: `${theme.spacing(2)} 0`,
              },
            }}
          >
            Total Card Quantity: {store.cart.offer?.numberOfTokens}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              padding: {
                xxxs: `${theme.spacing(2)} 0`,
              },
            }}
          >
            Credit Card Information
          </Typography>
          <form onSubmit={onSubmit} style={{ position: "relative" }}>
            <Box sx={{ display: loading ? "block" : "none" }}>
              <TransactionLoader type="Simple Loading" />
            </Box>
            <Grid
              container
              spacing={{ xxxs: 1, sm: 3 }}
              sx={{
                paddingBottom: { xxxs: theme.spacing(1), sm: theme.spacing(3) },
                opacity: loading ? "0" : "1",
              }}
            >
              <Grid item xxxs={12} xs={6}>
                <div
                  className="collect-div"
                  id="ccnumber"
                  style={{
                    borderColor:
                      validateFields.ccnumber === false ? theme.palette.color11.main : "",
                  }}
                />
              </Grid>
              <Grid item xxxs={12} xs={3}>
                <div
                  className="collect-div"
                  id="ccexp"
                  style={{
                    borderColor: validateFields.ccexp === false ? theme.palette.color11.main : "",
                  }}
                />
              </Grid>
              <Grid item xxxs={12} xs={3}>
                <div
                  className="collect-div"
                  id="cvv"
                  style={{
                    borderColor: validateFields.cvv === false ? theme.palette.color11.main : "",
                  }}
                />
              </Grid>
            </Grid>
            <Box className="total-price-section">
              <Divider />
              {priceRow("Subtotal", price.originalPrice)}
              {priceRow("Sales Tax", price.taxPrice)}
              {priceRow("Shipping Price", price.shippingPrice)}
              {priceRow("Fulfillment Fee", price.serviceFee)}
              <Divider />
              <Grid container className="prices">
                <Grid item>
                  <Typography variant="h6">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    $ {creditCardService.getTotalPrice().toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <ButtonBlue
              props={{
                variant: "contained",
                fullWidth: true,
                ref: payButton,
                className: "pay-button",
                disabled: disabledButton,
              }}
              included
              isSubmitType={true}
            >
              PAY ${creditCardService.getTotalPrice().toFixed(2)}
            </ButtonBlue>
          </form>
        </Box>
        {store.cart.threeDSInProgress && (
          <Box className="payment-form-overlay">
            <Typography variant="h6">Transaction in progress...</Typography>
            <Typography variant="h5">Please don't leave this page!</Typography>
            <CircularProgress size={50} color="secondary" />
            <Typography variant="h5" textAlign={"center"}>
              You might need to scroll on the box below.
            </Typography>
          </Box>
        )}
        <Box
          id="threeDSMountPoint"
          ref={threeDSMountPointRef}
          sx={store.cart.threeDSInProgress ? boxStyle : {}}
        ></Box>
        <Box sx={{ paddingTop: theme.spacing(3), textAlign: "center" }}>
          <SafeAndSecureIcon />
        </Box>
      </CreditCardPaymentStyle>
    </>
  );
};

export default CreditCardPayment;
