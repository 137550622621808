import { Box, styled } from '@mui/material';
import theme from '../../../utils/theme';

const FooterStyle = styled(Box)(() => ({
  width: "100%",
  backgroundColor: theme.palette.color18.main,
  "> .MuiContainer-root": {
    '> .MuiGrid-container': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      a: {
        color: theme.palette.secondary.dark,
      },
    },
    '.desktop-footer': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '.mobile-footer': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
  }
}));

export default FooterStyle;
