import {
  SmartyInternationalCanditateDataDTO,
  SmartyInternationalSelectedDTO,
  SmartyInternationValidationDTO,
  SmartyUsSuggestionDTO,
  SmartyUsValidationDTO,
} from "../dtos/SmartyRequestDTO";
import {
  SmartyInternationalCanditateItemModel,
  SmartyInternationalCanditateModel,
  SmartyModel,
  SmartyUsSuggestionModel,
  SmartyVerificationModel,
} from "../models/smarty";

const SmartyUsAutoCompleteMapper = (dto: SmartyUsSuggestionDTO): SmartyUsSuggestionModel => ({
  streetLine: dto.street_line,
  addressText:
    dto.street_line +
    `${dto.secondary ? " " + dto.secondary : ""}` +
    ", " +
    dto.city +
    ", " +
    dto.state +
    " " +
    dto.zipcode,
  ...dto,
});

const SmartyInternationalSuggestionMapper = (
  dto: SmartyInternationalCanditateDataDTO
): SmartyInternationalCanditateModel => ({
  addressId: dto.address_id,
  addressText: dto.address_text,
  entries: dto.entries,
});

const SmartyInternationalCanditateItemMapper = (
  dto: SmartyInternationalSelectedDTO
): SmartyInternationalCanditateItemModel => ({
  administrativeArea: dto.administrative_area,
  city: dto.locality,
  countryIso3: dto.country_iso3,
  postalCode: dto.postal_code,
  stateCode: dto.administrative_area_short,
  stateName: dto.administrative_area_long,
  street: dto.street,
});

const toModel = (
  dto: SmartyUsSuggestionDTO | SmartyInternationalCanditateDataDTO | SmartyInternationalSelectedDTO,
  isInternational: boolean,
  entries: number
): SmartyModel => {
  let result;
  if (isInternational) {
    if (entries > 1 || (dto as SmartyInternationalCanditateDataDTO).address_id) {
      result = dto as SmartyInternationalCanditateDataDTO;
      return {
        streetId: result.address_id,
        street: result.address_text,
        city: "",
        zipcode: "",
        state: "",
        inputText: result.address_text,
        isInternational,
        entries,
      };
    }
    result = dto as SmartyInternationalSelectedDTO;
    return {
      street: result.street,
      city: result.locality,
      zipcode: result.postal_code,
      state: result.administrative_area_short,
      inputText: result.street,
      isInternational,
      entries,
    };
  }
  result = dto as SmartyUsSuggestionDTO;
  return {
    street: result.street_line,
    city: result.city,
    zipcode: result.zipcode,
    state: result.state,
    secondaryAddress: result.secondary,
    inputText: `${result.street_line}${result.secondary ? " " + result.secondary : " "}, ${
      result.city
    }, ${result.state}, ${result.zipcode}`,
    isInternational,
    entries,
  };
};

const SmartyVerificationMapper = (
  dto: SmartyUsValidationDTO | SmartyInternationValidationDTO,
  isInternational: boolean
): SmartyVerificationModel => {
  let result;
  if (isInternational) {
    result = dto as SmartyInternationValidationDTO;

    let validation: "error" | "warning" | "success" = "error";
    if (result.analysis.verification_status) {
      switch (result.analysis.verification_status) {
        case "Verified":
          validation = "success";
          break;
        case "Partial":
          validation = "warning";
          break;
        case "Ambiguous":
          validation = "warning";
          break;
        case "None":
          validation = "error";
          break;
        default:
          validation = "error";
          break;
      }
    }

    return {
      isValidAddress: validation,
    };
  }
  result = dto as SmartyUsValidationDTO;
  let validation: "error" | "warning" | "success" = "error";
  if (result.analysis.dpv_match_code) {
    switch (result.analysis.dpv_match_code) {
      case "Y":
        validation = "success";
        break;
      case "S":
        validation = "warning";
        break;
      case "D":
        validation = "warning";
        break;
      case "N":
        validation = "error";
        break;
      default:
        validation = "error";
        break;
    }
  }
  return {
    isValidAddress: validation,
  };
};

export {
  SmartyInternationalCanditateItemMapper,
  SmartyInternationalSuggestionMapper,
  SmartyUsAutoCompleteMapper,
  SmartyVerificationMapper,
  toModel,
};
