import { Box, styled } from "@mui/material";
import theme from "../../../utils/theme";

const BillingAddressFormStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`
  },
  ".description": {
    fontSize: "20px",
  },
  ".total-card-quantity": {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
    marginTop: "10px",
    alignItems: "center",
  },
  ".change-button": {
    color: theme.palette.secondary.dark,
    textTransform: "capitalize",
    backgroundColor: theme.palette.color3.main,
    borderRadius: "50px",
    padding: "0px",
    minWidth: "73px",
  },
  ".small-button": {
    backgroundColor: theme.palette.color3.main,
    color: theme.palette.secondary.dark,
    boxShadow: "none",
    borderRadius: "50px",
    textTransform: 'capitalize',
    height: "22px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.color1.main,
    }
  },
  ".MuiButton-root": {
    textTransform: "none !important",
    "&.Mui-disabled": {
      color: theme.palette.color1.main
    }
  }
}));

export default BillingAddressFormStyle;
