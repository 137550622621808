import { ExploreOutlined, Mail } from "@mui/icons-material";
import { Alert, Grid, InputAdornment, Typography, Button } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import {
  ActiveStepModel,
  BillingAddress,
  ContainerMaxWidth,
  ShippingAddress,
} from "../../../contexts/Store";
import TextInput from "../../form/text-input/Component";
import BillingAddressFormStyle from "./Style";
import ButtonBlue from "../../form/button-blue/Component";
import { emailValidation, isValid } from "../../../utils/validation";
import { CONFIRM_MAILING_ADDRESS_RADIO_OPTIONS, INITIAL_FORM } from "./StaticData";
import { INITIAL_FORM as SHIPPING_INITIAL_FORM } from "../shipping-address-form/StaticData";
import CountryInput from "../../form/country-input/Component";
import StateInput from "../../form/state-input/Component";
import RadioGroupInput from "../../form/radio-group-input/Component";
import { componentTracking, hasPhysicalItem } from "../../../utils/utils";
import { useStoreContext } from "../../../hooks/Contexts";
import StreetInput from "../../form/street-input/Component";
import AddressValidationDialog from "../../form/address-validation-dialog/Component";

const BillingAddressForm: FC = (): JSX.Element => {
  const { store, setStore } = useStoreContext();

  const formInitialize = useMemo((): BillingAddress => {
    if (store.cart.addresses.billing.email) {
      return {
        ...INITIAL_FORM,
        ...store.cart.addresses.billing,
        email: store.cart.addresses.billing.email,
      };
    }
    return INITIAL_FORM;
  }, [store.cart.addresses.billing]);

  const [billingAddress, setBillingAddress] = useState<BillingAddress>(formInitialize);
  const [completed, setCompleted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "Enter Billing Details",
    });

    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          ...prevState.flowControl.steps.offers,
          title: "Checkout",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    if (store.cart.user.email) {
      setBillingAddress((prevState) => ({
        ...prevState,
        email: store.cart.user.email,
      }));
    }
  }, [store.cart.user.email]);

  const onChange = (property: string, value: string): void => {
    if (property === "country" && billingAddress.country !== value) {
      setBillingAddress((prevState) => ({
        ...prevState,
        city: "",
        postalCode: "",
        street: "",
        state: "",
        [property]: value,
      }));
      return;
    }
    setBillingAddress((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  useEffect(() => {
    setCompleted(
      isValid(INITIAL_FORM, billingAddress, [
        "email",
        "firstName",
        "lastName",
        "country",
        "street",
        "city",
        "state",
        "postalCode",
      ])
    );
  }, [billingAddress]);

  const shippingAddressHandler = (): ShippingAddress => {
    if (
      store.cart.offer &&
      hasPhysicalItem(store.cart.offer) &&
      billingAddress.confirmMailingAddress === "yes"
    ) {
      return {
        ...store.cart.addresses.shipping,
        ...billingAddress,
      };
    }

    return SHIPPING_INITIAL_FORM;
  };

  const creditCardActiveStepByQuantity = (): ActiveStepModel => {
    if (store.cart.offer && hasPhysicalItem(store.cart.offer)) {
      return {
        component: store.flowControl.steps.shippingAddressForm.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.creditCardPayment.component,
      title: "Checkout",
    };
  };

  const cryptoActiveStepByQuantity = (): ActiveStepModel => {
    if (store.cart.offer && hasPhysicalItem(store.cart.offer)) {
      return {
        component: store.flowControl.steps.cryptoPayment.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.shippingAddressForm.component,
      title: "Checkout",
    };
  };

  const activeStepHandler = (): ActiveStepModel => {
    if (store.cart.paymentMethod === "CreditCard") {
      return creditCardActiveStepByQuantity();
    }

    return cryptoActiveStepByQuantity();
  };

  const openValidationDialog = () => {
    if (!completed) {
      setFormError("Please fill in the required fields.");
      return;
    }
    if (!emailValidation(billingAddress.email)) {
      setFormError("The email address provided is invalid.");
      return;
    }
    setFormError("");

    setDialogIsOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  const save = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: activeStepHandler(),
      },
      cart: {
        ...prevState.cart,
        addresses: {
          ...prevState.cart.addresses,
          billing:
            prevState.cart.offer && !hasPhysicalItem(prevState.cart.offer)
              ? { ...billingAddress, confirmMailingAddress: "no" }
              : billingAddress,
          shipping: shippingAddressHandler(),
        },
      },
    }));
  };

  const changePackage = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          component: prevState.flowControl.steps.offers.component,
          title: "Checkout",
        },
      },
    }));
  };

  return (
    <BillingAddressFormStyle>
      <Grid container>
        <Grid item xxxs={12}>
          <Typography variant="h1">Enter Billing Details</Typography>
        </Grid>
        <Grid item xxxs={12} sx={{ my: 3 }} textAlign="center">
          <Typography variant="textMediumSemibold" className="description">
            Ensure your billing details match the credit card you will use.
          </Typography>
        </Grid>
        <Grid item xxs={12} className="total-card-quantity">
          <Typography variant="h6" component="p">
            Total Card Quantity: {store.cart.offer?.numberOfTokens}
          </Typography>
          <Button className="change-button" onClick={changePackage}>
            Change
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xxxs={12}>
          <TextInput
            label="E-mail"
            property="email"
            value={billingAddress.email}
            onChange={onChange}
            props={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              },
              autoFocus: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }} spacing={3}>
        <Grid item xxxs={12} xs={6}>
          <TextInput
            label="First Name"
            property="firstName"
            value={billingAddress.firstName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xxxs={12} xs={6}>
          <TextInput
            label="Last Name"
            property="lastName"
            value={billingAddress.lastName}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <CountryInput
            label="Select Country ..."
            property="country"
            onChange={onChange}
            value={billingAddress.country}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <StreetInput
            country={billingAddress.country}
            onChange={onChange}
            value={billingAddress.street}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }} spacing={3}>
        <Grid item xxxs={12} xs={6}>
          <TextInput label="City" property="city" value={billingAddress.city} onChange={onChange} />
        </Grid>
        <Grid item xxxs={12} xs={6}>
          <StateInput
            label="Select State / Province ..."
            property="state"
            value={billingAddress.state}
            onChange={onChange}
            country={billingAddress.country}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="ZIP Code / Postal Code"
            property="postalCode"
            value={billingAddress.postalCode}
            onChange={onChange}
            props={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <ExploreOutlined />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
      </Grid>
      {store.cart.offer && hasPhysicalItem(store.cart.offer) && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xxxs={12}>
            <Typography variant="h6">Confirm Mailing Address</Typography>
          </Grid>
          <Grid item xxxs={12}>
            <RadioGroupInput
              property="confirmMailingAddress"
              value={billingAddress.confirmMailingAddress}
              onChange={onChange}
              options={CONFIRM_MAILING_ADDRESS_RADIO_OPTIONS}
            />
          </Grid>
        </Grid>
      )}
      {formError && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xxxs={12}>
            <Alert severity="error">{formError}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <ButtonBlue
            props={{
              variant: "contained",
              fullWidth: true,
            }}
            onClick={openValidationDialog}
          >
            Continue
          </ButtonBlue>
        </Grid>
      </Grid>
      <AddressValidationDialog
        open={dialogIsOpen}
        onClose={handleCloseDialog}
        onSave={save}
        userAddress={billingAddress}
        label="billing"
      />
    </BillingAddressFormStyle>
  );
};

export default BillingAddressForm;
