import { ReactNode } from "react";
import {
  DisplayTypeEnum,
  OfferModel,
  PackageTypeEnum,
  PackageTypeModel,
} from "../../../models/offer";
import { Typography } from "@mui/material";
import { shippingPrice } from "../../../utils/constants";

export const styledParagraph = (children: ReactNode | string) => {
  return (
    <Typography variant="textMediumSmall" className="offer-list-description">
      {children}
    </Typography>
  );
};

export const PACKAGE_TYPES: PackageTypeModel = {
  [PackageTypeEnum.BASIC]: "Basic",
  [PackageTypeEnum.BRONZE]: "Bronze",
  [PackageTypeEnum.GUNMETAL]: "Gunmetal",
  [PackageTypeEnum.GOLD]: "Gold",
  [PackageTypeEnum.DIAMOND]: "Diamond",
  [PackageTypeEnum.GALA_DINNER]: "Gala Dinner",
};

export const OFFERS: OfferModel[] = [
  {
    packageType: PackageTypeEnum.BASIC,
    displayType: DisplayTypeEnum.BASIC,
    ribbon: -1,
    packageTitle: "Basic",
    header: "BUY 1-4  <br /> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 99,
      currency: "$",
    },
    numberOfTokens: 1,
    quantity: { max: 4, value: 1 },
    numberOfShippingItems: 0,
    packageRate: undefined,
    remaining: undefined,
    properties: undefined,
    features: undefined,
  },
  {
    packageType: PackageTypeEnum.BRONZE,
    displayType: DisplayTypeEnum.BRONZE,
    ribbon: -1,
    packageTitle: "Gold Sneaker Package",
    header: "BUY 5 <br /> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 495,
      currency: "$",
    },
    numberOfTokens: 5,
    quantity: undefined,
    numberOfShippingItems: 1,
    packageRate: undefined,
    remaining: undefined,
    properties: [
      {
        quantity: 5,
        name: "BRONZE FRAMED DIGITAL TRADING CARDS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PAIR OF GOLD SNEAKERS",
        image: "/images/offers/gold-low-top-1-pair.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45GLT-1": "Men 5 / Women 6.5",
              "45GLT-2": "Men 6 / Women 7.5",
              "45GLT-3": "Men 7 / Women 8.5",
              "45GLT-4": "Men 8 / Women 9.5",
              "45GLT-5": "Men 9 / Women 10.5",
              "45GLT-6": "Men 10 / Women 11.5",
              "45GLT-7": "Men 11 / Women 12.5",
              "45GLT-8": "Men 12 / Women 13.5",
              "45GLT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
    ],
    features: [
      {
        quantity: 1,
        name: `<b>One Pair Of "Never Surrender" Gold Trump Sneakers.</b> (First 1,000 Pairs Numbered) Order quickly to get the limited 1,000 numbered sneakers. International shipping is $${shippingPrice} per unit. Free Shipping In The U.S.`,
        image: "/images/offers/gold-low-top-1-pair.avif",
        physicalItem: undefined,
        label: 1,
      },
      {
        quantity: 5,
        name: "<b>5 Trump Digital Trading Cards</b> ($99 each, Some Could Be Rare)",
        image: "/images/offers/receive-12-cards.avif",
        physicalItem: undefined,
        label: 2,
      },
    ],
  },
  {
    packageType: PackageTypeEnum.GUNMETAL,
    displayType: DisplayTypeEnum.GUNMETAL,
    ribbon: -1,
    packageTitle: "2 Gold Sneaker Package",
    header: "BUY 9 <br /> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 891,
      currency: "$",
    },
    numberOfTokens: 12,
    quantity: undefined,
    numberOfShippingItems: 2,
    packageRate: undefined,
    remaining: undefined,
    properties: [
      {
        quantity: 12,
        name: "METAL FRAMED DIGITAL TRADING CARDS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 2,
        name: "PAIRS OF GOLD SNEAKERS ($998 Value)",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45GLT-1": "Men 5 / Women 6.5",
              "45GLT-2": "Men 6 / Women 7.5",
              "45GLT-3": "Men 7 / Women 8.5",
              "45GLT-4": "Men 8 / Women 9.5",
              "45GLT-5": "Men 9 / Women 10.5",
              "45GLT-6": "Men 10 / Women 11.5",
              "45GLT-7": "Men 11 / Women 12.5",
              "45GLT-8": "Men 12 / Women 13.5",
              "45GLT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
    ],
    features: [
      {
        quantity: 2,
        name: `<b>Two (2) Pairs Of "Never Surrender" Gold Trump Sneakers.</b> Wear One & Keep The Other As A Collectible, Or Give It To A Friend. First 1,000 Pairs Numbered. Order quickly to get the limited 1,000 numbered sneakers. International shipping is $${shippingPrice} per unit. Free Shipping In The U.S.`,
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: undefined,
        label: 1,
      },
      {
        quantity: 12,
        name: "<b>12 Trump Digital Trading Cards</b> (Three Extra Cards When You Purchase 9!)",
        image: "/images/offers/receive-12-cards.avif",
        physicalItem: undefined,
        label: 2,
      },
    ],
  },
  {
    packageType: PackageTypeEnum.GOLD,
    displayType: DisplayTypeEnum.GOLD,
    packageRate: { rate: 1, label: "good" },
    remaining: 0,
    ribbon: 0,
    packageTitle: "Super Collector Limited Edition Sneakers & Physical Card",
    header: "BUY 15 <br /> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 1485,
      currency: "$",
    },
    quantity: undefined,
    numberOfTokens: 50,
    numberOfShippingItems: 4,
    creditCardLimitation: 20,
    properties: [
      {
        quantity: 50,
        name: "GOLD FRAMED DIGITAL TRADING CARDS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PAIR PLATINUM HIGH TOP SNEAKERS",
        image: "/images/offers/limited-platinum-high-tops.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45PSWT-HT-1": "Men 5 / Women 6.5",
              "45PSWT-HT-2": "Men 6 / Women 7.5",
              "45PSWT-HT-3": "Men 7 / Women 8.5",
              "45PSWT-HT-4": "Men 8 / Women 9.5",
              "45PSWT-HT-5": "Men 9 / Women 10.5",
              "45PSWT-HT-6": "Men 10 / Women 11.5",
              "45PSWT-HT-7": "Men 11 / Women 12.5",
              "45PSWT-HT-8": "Men 12 / Women 13.5",
              "45PSWT-HT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
      {
        quantity: 2,
        name: "PAIRS OF GOLD SNEAKERS ($998 Value)",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45GLT-1": "Men 5 / Women 6.5",
              "45GLT-2": "Men 6 / Women 7.5",
              "45GLT-3": "Men 7 / Women 8.5",
              "45GLT-4": "Men 8 / Women 9.5",
              "45GLT-5": "Men 9 / Women 10.5",
              "45GLT-6": "Men 10 / Women 11.5",
              "45GLT-7": "Men 11 / Women 12.5",
              "45GLT-8": "Men 12 / Women 13.5",
              "45GLT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
      {
        quantity: 1,
        name: "DIGITAL NFT DEBATE CARD",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PHYSICAL CARD W/ SUIT",
        image: "",
        physicalItem: {
          selectedSizes: { "45PDC-RELIC-1": "45PDC-RELIC-1" },
        },
      },
      {
        quantity: 1,
        name: "ONE-OF-ONE DIGITAL TRADING CARD (NFT)",
        image: "",
        physicalItem: undefined,
      },
    ],
    features: [
      {
        quantity: 1,
        name: "<b>One Pair Of Platinum High Top Sneakers.</b> (Limited to 1,524 Pairs, each numbered)",
        image: "/images/offers/limited-platinum-high-tops.avif",
        physicalItem: undefined,
        label: 1,
      },
      {
        quantity: 2,
        name: "<b>Two Pairs of Never Surrender Sneakers ($998 Value)</b>",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: undefined,
        label: 2,
      },
      {
        quantity: 1,
        name: `<b>Limited Physical Trading Card w/ Piece Of President Trump's Suit From The Debate on June 27.</b> Only 2,024 Available with Different Rarities. Some Are Randomly Autographed.  International shipping is $${shippingPrice} per unit. Free Shipping In The U.S.`,
        image: "/images/offers/suit-only-physical-card.avif",
        physicalItem: undefined,
        label: 3,
      },
      {
        quantity: 1,
        name: "<b>One GUARANTEED One-of-One Digital Trading Card (NFT). The Only One In The World!</b>",
        image: "", // need image
        physicalItem: undefined,
        label: 4,
      },
      {
        quantity: 1,
        name: "<b>Get The Official Digital Debate Trading Card.</b> Only 2,024 available with different rarities. Numbered & Limited. Some are Autographed.",
        image: "",
        physicalItem: undefined,
        label: 5,
      },
      {
        quantity: 50,
        name: "<b>50 Trump Digital Trading Cards</b>",
        image: "/images/offers/receive-50-cards.avif",
        physicalItem: undefined,
        label: 6,
      },
    ],
  },
  {
    packageType: PackageTypeEnum.GALA_DINNER,
    displayType: DisplayTypeEnum.GOLD,
    packageRate: { rate: 3, label: "best" },
    remaining: 0,
    ribbon: 0,
    packageTitle: "Dinner With President Trump & Very Limited Edition Sneakers",
    header: "Gala Dinner <br/> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 2997,
      currency: "$",
    },
    quantity: undefined,
    numberOfTokens: 50,
    numberOfShippingItems: 4,
    creditCardLimitation: 20,
    properties: [
      {
        quantity: 1,
        name: "DINNER W/TRUMP",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 50,
        name: "GOLD FRAMED DIGITAL TRADING CARDS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PAIR PLATINUM HIGH TOP SNEAKERS",
        image: "/images/offers/limited-platinum-high-tops.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45PSWT-HT-1": "Men 5 / Women 6.5",
              "45PSWT-HT-2": "Men 6 / Women 7.5",
              "45PSWT-HT-3": "Men 7 / Women 8.5",
              "45PSWT-HT-4": "Men 8 / Women 9.5",
              "45PSWT-HT-5": "Men 9 / Women 10.5",
              "45PSWT-HT-6": "Men 10 / Women 11.5",
              "45PSWT-HT-7": "Men 11 / Women 12.5",
              "45PSWT-HT-8": "Men 12 / Women 13.5",
              "45PSWT-HT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
      {
        quantity: 2,
        name: "PAIRS OF GOLD SNEAKERS ($998 Value)",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45GLT-1": "Men 5 / Women 6.5",
              "45GLT-2": "Men 6 / Women 7.5",
              "45GLT-3": "Men 7 / Women 8.5",
              "45GLT-4": "Men 8 / Women 9.5",
              "45GLT-5": "Men 9 / Women 10.5",
              "45GLT-6": "Men 10 / Women 11.5",
              "45GLT-7": "Men 11 / Women 12.5",
              "45GLT-8": "Men 12 / Women 13.5",
              "45GLT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
      {
        quantity: 1,
        name: "DIGITAL NFT DEBATE CARD",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PHYSICAL CARD W/ SUIT",
        image: "",
        physicalItem: {
          selectedSizes: { "45PDC-RELIC-1": "45PDC-RELIC-1" },
        },
      },
      {
        quantity: 1,
        name: "ONE-OF-ONE DIGITAL TRADING CARD (NFT)",
        image: "",
        physicalItem: undefined,
      },
    ],
    features: [
      {
        quantity: 1,
        name: "<b>Dinner With President Trump. 1 Ticket For YOU To Attend President Trump's Gala Dinner At his Private Club in Palm Beach County, Florida.</b> Once-In-A-Lifetime Experience That Could Be YOURS!",
        image: "/images/offers/dinner-with-trump.webp",
        physicalItem: undefined,
        label: 1,
      },
      {
        quantity: 1,
        name: "<b>One Pair Of Platinum High Top Sneakers.</b> (Limited to 1,524 Pairs, each numbered)",
        image: "/images/offers/limited-platinum-high-tops.avif",
        physicalItem: undefined,
        label: 2,
      },
      {
        quantity: 2,
        name: "<b>Two Pairs of Never Surrender Sneakers ($998 Value)</b>",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: undefined,
        label: 3,
      },
      {
        quantity: 1,
        name: `<b>Limited Physical Trading Card w/ Piece Of President Trump's Suit From The Debate on June 27.</b> Only 2,024 Available with Different Rarities. Some Are Randomly Autographed.  International shipping is $${shippingPrice} per unit. Free Shipping In The U.S.`,
        image: "/images/offers/suit-only-physical-card.avif",
        physicalItem: undefined,
        label: 4,
      },
      {
        quantity: 1,
        name: "<b>One GUARANTEED One-of-One Digital Trading Card (NFT). The Only One In The World!</b>",
        image: "", // need image
        physicalItem: undefined,
        label: 5,
      },
      {
        quantity: 1,
        name: "<b>Get The Official Digital Debate Trading Card.</b> Only 2,024 available with different rarities. Numbered & Limited. Some are Autographed.",
        image: "",
        physicalItem: undefined,
        label: 6,
      },
      {
        quantity: 50,
        name: "<b>50 Trump Digital Trading Cards</b>",
        image: "/images/offers/receive-50-cards.avif",
        physicalItem: undefined,
        label: 7,
      },
    ],
  },
  {
    packageType: PackageTypeEnum.DIAMOND,
    displayType: DisplayTypeEnum.DIAMOND,
    packageRate: { rate: 2, label: "amazing" },
    remaining: 0,
    ribbon: 25,
    packageTitle: "Two (2) ULTRA VIP DINNERS",
    header: "BUY 250 <br /> DIGITAL TRADING CARDS <span>(NFTs)</span>",
    price: {
      originalPrice: 25000,
      currency: "$",
    },
    quantity: undefined,
    numberOfTokens: 250,
    numberOfShippingItems: 5,
    properties: [
      {
        quantity: 2,
        name: "TWO ULTRA VIP DINNERS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 2,
        name: "TWO PRE-GALA COCKTAIL RECEPTION W/TRUMP",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 250,
        name: "DIAMOND FRAMED DIGITAL TRADING CARDS",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PAIR AUTOGRAPHED PLATINUM HIGH TOP SNEAKERS (RARE)",
        image: "/images/offers/signed-limited-platinum-high-tops.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45PSWT-SDHT-1": "Men 5 / Women 6.5",
              "45PSWT-SDHT-2": "Men 6 / Women 7.5",
              "45PSWT-SDHT-3": "Men 7 / Women 8.5",
              "45PSWT-SDHT-4": "Men 8 / Women 9.5",
              "45PSWT-SDHT-5": "Men 9 / Women 10.5",
              "45PSWT-SDHT-6": "Men 10 / Women 11.5",
              "45PSWT-SDHT-7": "Men 11 / Women 12.5",
              "45PSWT-SDHT-8": "Men 12 / Women 13.5",
              "45PSWT-SDHT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },
      {
        quantity: 2,
        name: "PAIRS OF GOLD SNEAKERS ($998 Value)",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: {
          selectedSizes: {},
          sizes: {
            selected: "options",
            options: {
              "45GLT-1": "Men 5 / Women 6.5",
              "45GLT-2": "Men 6 / Women 7.5",
              "45GLT-3": "Men 7 / Women 8.5",
              "45GLT-4": "Men 8 / Women 9.5",
              "45GLT-5": "Men 9 / Women 10.5",
              "45GLT-6": "Men 10 / Women 11.5",
              "45GLT-7": "Men 11 / Women 12.5",
              "45GLT-8": "Men 12 / Women 13.5",
              "45GLT-9": "Men 13 / Women 14.5",
            },
          },
        },
      },

      {
        quantity: 2,
        name: "DIGITAL NFT DEBATE CARD",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "PHYSICAL CARD W/ SUIT",
        image: "",
        physicalItem: {
          selectedSizes: { "45PDC-RELIC-1": "45PDC-RELIC-1" },
        },
      },
      {
        quantity: 1,
        name: "Physical Debate Jumbo Holographic Foil Card",
        image: "",
        physicalItem: {
          selectedSizes: { "45PDC-RELIC-2": "45PDC-RELIC-2" },
        },
      },
      {
        quantity: 1,
        name: "COMMEMORATIVE DINNER NFT CARD",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "1-1 DIGITAL TRADING CARD (NFT)",
        image: "",
        physicalItem: undefined,
      },
      {
        quantity: 1,
        name: "ONE-OF-ONE BITCOIN ORDINAL- Very Rare",
        image: "",
        physicalItem: undefined,
      },
    ],
    features: [
      {
        quantity: 2,
        name: "<b>Two (2) ULTRA VIP Tickets For YOU and Your Friend</b> To Attend the President's Gala Dinner At his Private Club in Jupiter, Florida. Sit at the VIP Table! (Limited to 25 Buyers)",
        image: "/images/offers/dinner-with-trump.webp",
        physicalItem: undefined,
        label: 1,
      },
      {
        quantity: 2,
        name: "<b>Two (2) Ultra VIP Tickets For YOU And Your Friend To Have Cocktails With President Trump Before The Gala Dinner.</b> (Very Exclusive-Limited To 50 People) Once These Sell Out, They Are Gone. This once in a lifetime experience could be YOURS!",
        image: "/images/offers/reception-with-trump.avif",
        physicalItem: undefined,
        label: 2,
      },
      {
        quantity: 1,
        name: "<b>One Pair of Guaranteed Autographed Limited-Edition Platinum High Top Sneakers.</b> (Limited to 25 Pairs, each Numbered)",
        image: "/images/offers/signed-limited-platinum-high-tops.avif",
        physicalItem: undefined,
        label: 3,
      },
      {
        quantity: 2,
        name: "<b>Two Pairs of Trump Sneakers ($998 Value)</b>",
        image: "/images/offers/gold-high-top-2-pair.avif",
        physicalItem: undefined,
        label: 4,
      },
      {
        quantity: 2,
        name: `<b>Two (2) Limited Edition Physical Trading Cards. </b> <br /> > One Has A Piece Of President Trump's Suit From June 27. Only 2,024 Available With Different Rarities. <br /><br /> > The Second Card is a Jumbo shimmering Holographic Foil card with an even larger piece of the suit fabric. Only 25 Of These Available. Some Cards May Be Autographed By President Trump. Shipped Free To U.S. Addresses. Extremely, <u>Extremely Rare</u>. A True Collector’s Item.`,
        image: "/images/offers/suit-physical-card.webp",
        physicalItem: undefined,
        label: 5,
      },
      {
        quantity: 1,
        name: "<b>One GUARANTEED One-of-One Digital Trading Card (NFT). The Only One In The World!</b>",
        image: "", // need image
        physicalItem: undefined,
        label: 6,
      },
      {
        quantity: 250,
        name: "<b>250 Trump Digital Trading Cards</b>",
        image: "/images/offers/receive-250-cards.avif",
        physicalItem: undefined,
        label: 7,
      },
      {
        quantity: 1,
        name: "<b>One Limited Commemorative Dinner NFT Card.</b> (Only 500 Available!)",
        image: "", // need image
        physicalItem: undefined,
        label: 8,
      },
      {
        quantity: 1,
        name: '<b>Two Limited Edition Digital "Debate" NFT Cards.</b> (Only 2,024 Available! 5 are Randomly Autographed!)',
        image: "",
        physicalItem: undefined,
        label: 9,
      },
      {
        quantity: 1,
        name: "<b>BONUS: Limited-Edition 1-Of-1 Trump Bitcoin Ordinal.</b> (Only 25 Available!)",
        image: "/images/offers/receive-ordinal.avif",
        physicalItem: undefined,
        label: 10,
      },
    ],
  },
];
