import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { FC } from "react";

type Props = {
  options: { [key: string]: string };
  label: string;
  selectProps?: SelectProps;
};

const PhysicalItemSizeInput: FC<Props> = ({ options, label, selectProps }) => {
  return (
    <FormControl size="small" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select color="primary" {...selectProps} MenuProps={{ disableScrollLock: true}} size="small" label={label}>
        {Object.keys(options).map((sku, index) => (
          <MenuItem key={index} value={sku}>
            {options[sku]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PhysicalItemSizeInput;
