import { useCallback } from "react";
import { serviceFeePercentage, shippingPrice } from "../utils/constants";
import useTaxService from "./Tax";
import { useStoreContext } from "../hooks/Contexts";
import { getQuantityByOffer } from "../utils/utils";
import { PackageTypeEnum } from "../models/offer";
import useCountryService from "./Country";

const useCreditCardService = () => {
  const taxService = useTaxService();
  const { store } = useStoreContext();
  const countryService = useCountryService();

  const getOriginalPrice = useCallback((): number => {
    return getQuantityByOffer(store.cart.offer!) * store.cart.offer!.price.originalPrice;
  }, [store.cart.offer]);

  const getTaxPrice = useCallback((): number => {
    const tax: number = taxService.getByPostalCode(store.cart.addresses.billing.postalCode);
    if (tax === 0) return 0;

    const value = (getOriginalPrice() * tax) / 100;
    return Number(value.toFixed(2));
  }, [store.cart.addresses.billing.postalCode]);

  const getShippingPrice = useCallback((): number => {
    if (
      store.cart.offer!.packageType > PackageTypeEnum.BASIC &&
      countryService.getCodeByName(store.cart.addresses.shipping.country) === "US"
    ) {
      return 0;
    }
    return shippingPrice * store.cart.offer!.numberOfShippingItems;
  }, [store.cart.offer]);

  const getTaxedOriginPrice = useCallback((): number => {
    return getOriginalPrice() + getTaxPrice();
  }, []);

  const getFeePrice = useCallback((): number => {
    if (store.cart.addresses.billing.state === "California") return 0;
    const value = ((getTaxedOriginPrice() + getShippingPrice()) * serviceFeePercentage) / 100;
    return Number(value.toFixed(2));
  }, [store.cart.addresses.billing.state]);

  const getTotalPrice = useCallback(() => {
    return getTaxedOriginPrice() + getShippingPrice() + getFeePrice();
  }, []);

  return {
    getOriginalPrice,
    getTaxPrice,
    getShippingPrice,
    getTaxedOriginPrice,
    getFeePrice,
    getTotalPrice,
  };
};

export default useCreditCardService;
