import { useCallback } from "react";
import { api } from "../utils/api";
import PayStatusDTO from "../dtos/payStatusDTO";
import { payConfigEndpoint, payStatusEndpoint } from "../utils/constants";
import PayConfigModel from "../models/payConfig";
import PayConfigDTO from "../dtos/payConfigDTO";
import { getRandomNumber } from "../utils/utils";

const usePayStatus = () => {
  const status = useCallback(async (): Promise<boolean> => {
    const { data } = await api.get<PayStatusDTO>(`${payStatusEndpoint as string}?nocache=${getRandomNumber()}`);
    return data.soldOut;
  }, []);

  const config = useCallback(async (): Promise<PayConfigModel> => {
    const { data } = await api.get<PayConfigDTO>(`${payConfigEndpoint as string}?nocache=${getRandomNumber()}`);
    return data;
  }, []);

  return {
    status,
    config
  };
};

export default usePayStatus;
