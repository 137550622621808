import { useCallback } from "react";
import { api } from "../utils/api";
import ProductDTO from "../dtos/productDTO";
import { getRandomNumber } from "../utils/utils";
import { counterEndpoint } from "../utils/constants";

const usePhysicalItemService = () => {
  const getProducts = useCallback(async (): Promise<ProductDTO[]> => {
    const { data } = await api.get<ProductDTO[]>(
      `${counterEndpoint}/products.json?v=${getRandomNumber()}`
    );
    return data;
  }, []);

  return { getProducts };
};

export default usePhysicalItemService;
