import { FC, useEffect, useState } from "react";
import DiamondMinterStyle from "./Style";
import useContractService from "../../../services/Contract";
import {
  Box,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ButtonBlue from "../../form/button-blue/Component";
import { chainId, collectTrumpCardsSite, maxMintableTokens } from "../../../utils/constants";
import { toast } from "react-toast";

const steps: { id: number; label: string; value: number }[] = [
  {
    id: 1,
    label: "First mint",
    value: 100,
  },
  {
    id: 2,
    label: "Second mint",
    value: 100,
  },
  {
    id: 3,
    label: "Last mint",
    value: 50,
  },
];

const DiamondMinter: FC = () => {
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState<string | null>(null);
  const [currentChain, setCurrentChain] = useState<number | null>(null);
  const { getDiamondMintRecords, mintFromLocked } = useContractService();

  const getCurrentStep = async () => {
    const mintRecords = await getDiamondMintRecords();
    if (mintRecords) {
      const minted = mintRecords.minted;
      const start = mintRecords.start;
      if (start > 0) {
        if (minted < maxMintableTokens) {
          if (minted === 0) setActiveStep(0);
          if (minted === 100) setActiveStep(1);
          if (minted === 200) setActiveStep(2);
        }
        if (minted === maxMintableTokens)
          window.location.href = `${collectTrumpCardsSite}/thank-you?nftAmount=${minted}`;
      }
    }
  };

  const mintDiamondCards = async () => {
    if (activeStep === null) return;
    if (currentChain !== null) {
      if (currentChain !== Number(chainId)) {
        toast.error("Please change the network to Polygon");
        return;
      }
    }
    setLoading(true);
    const mintTransaction = await mintFromLocked(steps[activeStep].value);
    if (mintTransaction !== undefined) {
      setTxHash(mintTransaction?.hash);
      await mintTransaction.wait();
      await getCurrentStep();
    }
    setLoading(false);
    setTxHash(null);
  };

  useEffect(() => {
    (async () => {
      await getCurrentStep();
      const ethereum = (window as any).ethereum;
      if (ethereum) {
        ethereum.on("chainChanged", (chainId: string) => {
          setCurrentChain(Number(chainId));
        });
      }
    })();
  }, []);

  if (activeStep === null) return <Typography>Loading...</Typography>;

  return (
    <DiamondMinterStyle>
      <Typography variant="h1">Mint 250 NFTs</Typography>
      <Typography
        variant="textMediumNormal"
        component="p"
        textAlign="center"
        sx={{ padding: `24px 0` }}
      >
        All transactions are secure and encrypted.
      </Typography>
      <Stepper className="stepper" activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.id} expanded sx={{ minWidth: "300px" }}>
            <StepLabel>
              <Typography variant="textRegularSemibold">{`${step.label} (${step.value})`}</Typography>
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                {activeStep > index && (
                  <Typography variant="textRegularSemibold">Completed</Typography>
                )}
                {activeStep === index && txHash && (
                  <Box>
                    <Box display="flex" gap="5px" alignItems="center">
                      <CircularProgress size={20} />
                      <Typography>Transaction in progress</Typography>
                    </Box>
                    <Typography>
                      {" "}
                      You can check it on{" "}
                      <a href={"https://polygonscan.com/tx/" + txHash} target="blank">
                        Polygonscan!
                      </a>{" "}
                    </Typography>
                  </Box>
                )}
                {(activeStep < index || (activeStep === index && !txHash)) && (
                  <ButtonBlue
                    props={{
                      variant: "contained",
                      fullWidth: true,
                      sx: { mt: 1, mr: 1 },
                      disabled: loading || activeStep !== index,
                      onClick: () => mintDiamondCards(),
                    }}
                  >
                    Mint
                  </ButtonBlue>
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </DiamondMinterStyle>
  );
};

export default DiamondMinter;
