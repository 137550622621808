import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import useKYCService from "../../services/KYC";
import { useStoreContext } from "../../hooks/Contexts";
import { ActiveStepModel, ContainerMaxWidth } from "../../contexts/Store";
import TransactionLoader from "../transaction-loader/Component";
import { hasPhysicalItem } from "../../utils/utils";

const KYC = () => {
  const [accessToken, setAccessToken] = useState("");
  const kycService = useKYCService();
  const { store, setStore } = useStoreContext();

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          ...prevState.flowControl.steps.kycForm,
          title: "Checkout",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await kycService.getAccessTokenForKYC();
      setAccessToken(token);
    };
    getAccessToken();
  }, []);

  const activeStepHandler = (): ActiveStepModel => {
    return {
      component: hasPhysicalItem(store.cart.offer!)
        ? store.flowControl.steps.shippingAddressForm.component
        : store.flowControl.steps.cryptoPayment.component,
      title: "Checkout",
    };
  };

  const messageHandler = (event: any, status: any) => {
    if (
      event === "idCheck.onApplicantStatusChanged" &&
      status.reviewResult?.reviewAnswer === "GREEN"
    ) {
      setStore((prevState) => ({
        ...prevState,
        flowControl: {
          ...prevState.flowControl,
          activeStep: activeStepHandler(),
        },
      }));
    }
  };

  const errorHandler = (error: any) => {
    console.error(error, " error");
  };

  return (
    <>
      {accessToken ? (
        <SumsubWebSdk
          accessToken={accessToken}
          expirationHandler={() => Promise.resolve(accessToken)}
          config={{
            lang: "en",
            email: store.cart.kycForm.email,
          }}
          options={{}}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      ) : (
        <TransactionLoader type="Simple Loading" />
      )}
    </>
  );
};

export default KYC;
