import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import OffersStyle from "./Style";
import { useStoreContext } from "../../../hooks/Contexts";
import { BonusBox } from "../../checkout-card/offer-list/components/BonusComponent";
import theme from "../../../utils/theme";
import { ContainerMaxWidth } from "../../../contexts/Store";
import { DoubleDownArrow } from "../../../utils/svgIcon";
import CheckoutCard from "../../checkout-card/Component";
import { OFFERS } from "./OffersData";
import QuantitySelector from "../../quantity-selector/Component";
import { OfferModel, PackageTypeEnum } from "../../../models/offer";
import useCounterService from "../../../services/Counter";
import { componentTracking, isOnlyBuyWithCrypto } from "../../../utils/utils";

export interface OfferListItem {
  description: React.ReactNode;
  soldOut: boolean;
  bonusBox: BonusBox;
  underline?: boolean;
}

const Offers: FC = () => {
  const { store, setStore } = useStoreContext();
  const { updateOffersWithRemaining } = useCounterService();
  const [offers, setOffers] = useState<OfferModel[]>(OFFERS);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);

  const getOfferIdFromURL = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("package");
  };

  useEffect(() => {
    (async () => {
      if (!store.cart.offer && !getOfferIdFromURL()) {
        const updatedOffers = await updateOffersWithRemaining(OFFERS);
        setOffers(updatedOffers);
        setLoading(false);
      }
    })();
  }, [store.cart.offer, updateOffersWithRemaining]);

  useEffect(() => {
    (async () => {
      const offerId = getOfferIdFromURL();
      setSelectedOfferId(Number(offerId));
      let existsOffer: OfferModel | undefined;
      if (offerId) {
        const updatedOffers = await updateOffersWithRemaining(OFFERS);
        existsOffer = updatedOffers.find((offer) => offer.packageType === +offerId);
        if (existsOffer) setOffers(offers.filter((offer) => offer.packageType === +offerId));
      }
      setStore((prevState) => ({
        ...prevState,
        cart: {
          ...prevState.cart,
          offer: existsOffer ?? prevState.cart.offer,
        },
        flowControl: {
          ...prevState.flowControl,
          activeStep: {
            ...prevState.flowControl.activeStep,
            componentName: "Offers",
          },
          backStep: {
            ...prevState.flowControl.steps.paymentMethods,
            title: "Checkout",
            componentName: "",
          },
        },
        ///put back the container width to full on the bottom
        containerMaxWidth: ContainerMaxWidth.MIDDLE,
      }));
      setLoading(false);
    })();
  }, [store.cart.paymentMethod]);

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "Select Your Purchase Option",
    });
  }, []);

  const getSubtitleByPaymentMethod = () => {
    return (
      <Typography
        variant="h3"
        sx={{ textAlign: "center", paddingTop: theme.spacing(3), textTransform: "capitalize" }}
      >
        DON'T MISS {offers.length === 1 ? "OUT ON THIS OFFER..." : `THESE AMAZING OFFERS...`}{" "}
      </Typography>
    );
  };

  useEffect(() => {
    const offer = offers.find((offer) => offer.packageType === store.cart.offer?.packageType);
    if (store.cart.paymentMethod === "CreditCard" && offer && isOnlyBuyWithCrypto(offer)) {
      setStore((prevState) => ({
        ...prevState,
        cart: {
          ...prevState.cart,
          offer: null,
        },
      }));
    }
  }, [offers, store.cart.offer]);

  if (loading) return null;

  const isHiddenOffer = (offer: OfferModel): boolean => {
    const isSpecialPackage =
      offer.packageType === PackageTypeEnum.DIAMOND ||
      offer.packageType === PackageTypeEnum.GALA_DINNER;
    return (
      !isSpecialPackage ||
      selectedOfferId === PackageTypeEnum.DIAMOND ||
      selectedOfferId === PackageTypeEnum.GALA_DINNER
    );
  };

  return (
    <OffersStyle>
      <Box>
        <Typography variant="h1">Select Your Purchase Option:</Typography>
        {store.cart.paymentMethod == "CreditCard" && (
          <Typography variant="h6" component="p" className="one-transaction-text">
            Only 1 transaction per person!
          </Typography>
        )}
        {getSubtitleByPaymentMethod()}
        <Box className="double-arrow-svg-box">
          <DoubleDownArrow />
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent={"center"}
        className="main-grid-container"
        sx={{ paddingBottom: store.cart.offer ? "60px" : 0 }}
      >
        {offers
          .filter((card) => isHiddenOffer(card))
          .map((card) => (
            <Grid item key={card.packageType} xxs={12} md={4} className="offer-grid">
              {card.quantity === undefined ? (
                <CheckoutCard offer={card} openedShowMore={offers.length === 1} />
              ) : (
                <QuantitySelector offer={card} />
              )}
            </Grid>
          ))}
      </Grid>
    </OffersStyle>
  );
};

export default Offers;
