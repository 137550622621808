export const collectTrumpCardsSite = process.env.REACT_APP_HOME_SITE;
// web3auth variables
export const web3authClientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;
export const chainId = process.env.REACT_APP_CHAIN_ID;
export const rpcTarget = process.env.REACT_APP_RPC_TARGET;
export const web3authNetwork: any = process.env.REACT_APP_WEB3_AUTH_NETWORK;

//contract addresses
export const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
export const minterAddress = process.env.REACT_APP_MINTER_ADDRESS;
export const wethAddress = process.env.REACT_APP_WETH_ADDRESS;

//google recaptcha
export const recaptchaSiteKey: any = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

//api
export const startKycEndpoint = `${process.env.REACT_APP_VERIFY_API}/startKyc`;
export const verifyUserDataEndpoint = `${process.env.REACT_APP_VERIFY_API}/verifyUserData`;
export const paymentServer = process.env.REACT_APP_PAYMENT_SERVER as string;
export const counterEndpoint = process.env.REACT_APP_COUNTER_API;
export const payStatusEndpoint = `${process.env.REACT_APP_COUNTER_API}/status.json`;
export const payConfigEndpoint = `${process.env.REACT_APP_COUNTER_API}/config.json`;

//others
export const appDomain = process.env.REACT_APP_DOMAIN;
export const serviceFeePercentage: number = 4.5;
export const shippingPrice: number = 45;

//Smarty
export const smartyKey = `${process.env.REACT_APP_SMARTY_KEY}`;
export const smartyUsAutoCompleteApi: string = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${smartyKey}`;
export const smartyInternationalAutoCompleteApi: string = `https://international-autocomplete.api.smarty.com/v2/lookup`;
export const smartyInternationalValidateApi: string = `https://international-street.api.smarty.com/verify?license=international-geocoding-cloud&key=${smartyKey}`;
export const smartyUsValidateApi: string = `https://us-street.api.smarty.com/street-address?key=${smartyKey}&license=us-core-enterprise-cloud`;

export const maxMintableTokens = 250;
