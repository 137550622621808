import { styled, Box } from "@mui/material";

const CheckoutCardStyle = styled(Box)(({ theme }) => ({
  position: "relative",
  border: `3px dashed ${theme.palette.primary.light}`,
  padding: "3px",
  ".add-to-cart": {
    margin: "0 16px",
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  "& hr": {
    borderBottom: `1px solid ${theme.palette.color3.main}`,
  },
  ".inside-box": {
    background: theme.palette.color12.main,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      minHeight: "326px",
    },
    "&.higher": {
      minHeight: "560px",
    },
    "&.higher.credit-payment": {
      minHeight: "591px",
    },
  },
  ".selected-checkbox": {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  ".title-stripe": {
    minHeight: "106px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
    overflow: "hidden",
    padding: "5px 0px",
  },
  ".sticky-offer-header": {
    position: "sticky",
    top: "75px",
    bottom: "15px",
    zIndex: "1",
  },
  ".red-flag": {
    height: "35px",
    width: "100px",
    background: theme.palette.color2.main,
    position: "absolute",
    top: 0,
    left: 0,
    transform: "rotate(-45deg)",
    transformOrigin: "0 0",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".limited-text": {
    color: "#fff",
    fontSize: "11px",
    lineHeight: "13px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  ".bonus-type-box": {
    backgroundColor: theme.palette.color1.main,
    border: `2px dotted ${theme.palette.color2.main}`,
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px",
    height: "fit-content",
  },
  ".additional-details": {
    padding: theme.spacing(2),
    img: {
      maxWidth: "100%",
    },
  },
  ".additional-details-item": {
    display: "flex",
    marginBottom: "10px",
    gap: "10px",
  },
  ".bonus-type-label": {
    color: theme.palette.color2.main,
    lineHeight: "14px",
  },
  ".show-more-box": {
    margin: "0 16px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    display: "flex",
    backgroundColor: theme.palette.color12.dark,
    cursor: "pointer",
  },
  ".bonus-text": {
    fontSize: "16px",
    fontFamily: "SF UI Display Medium",
    b: {
      fontFamily: "SF UI Display Bold",
    },
  },
  ".selected-package": {
    color: theme.palette.color2.main,
    path: {
      fill: theme.palette.color2.main,
    },
  },
  ".titles": {
    display: "flex",
    maxWidth: "260px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    ".offer-header": {
      textTransform: "uppercase",
      color: theme.palette.color19.main,
      maxWidth: "237px",
      fontSize: "20px",
      lineHeight: "21px",
      span: {
        textTransform: "none",
      },
    },
    ".offer-package-title": {
      marginTop: theme.spacing(1),
      textTransform: "uppercase",
      color: theme.palette.color1.main,
      fontSize: "15px",
      lineHeight: "15.75px",
    },
  },
}));

export default CheckoutCardStyle;
