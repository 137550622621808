import { ShippingAddress } from "../../../contexts/Store";

export const INITIAL_FORM: ShippingAddress = {
  firstName: "",
  lastName: "",
  street: "",
  country: "United States",
  state: "Alabama",
  city: "",
  postalCode: "",
  apartment: "",
  phone: ""
};
