import { Box, styled } from "@mui/material";

const PhyisicalItemSizesStyle = styled(Box)(({ theme }) => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  img: {
    maxWidth: "130px",
    height: "auto",
    margin: "0 auto",
  },
  ".description": {
    lineHeight: "30px",
    fontSize: "20px",
  },
  ".atention-text": {
    color: theme.palette.color2.main,
  },
  ".physical-item": {
    border: `1px solid ${theme.palette.secondary.dark}`,
    height: "100%",
    ".physical-item-details": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
  },
  ".physical-item-description, .physical-item-select-size": {
    textAlign: "center",
  },
  ".physical-item-select-size": {
    paddingTop: "16px",
  },
  ".physical-item-selection": {
    marginTop: "10px",
    label: {
      "&.Mui-focused": {
        color: `${theme.palette.secondary.dark} !important`,
      },
    },
    fieldset: {
      borderColor: `${theme.palette.secondary.dark} !important`,
    },
  },
  ".continue-button": {
    marginTop: "24px",
  },
}));

export default PhyisicalItemSizesStyle;
