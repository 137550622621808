import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DropdownModel from "../../../models/dropdown";
import StateInputStyle from "./Style";
import useStateService from "../../../services/State";

type Props = {
  label: string;
  property: string;
  value: string;
  country: string;
  onChange: (property: string, value: string) => void;
};

const INITIAL_SELECTED: DropdownModel = { id: "", label: "" };

const StateInput: FC<Props> = ({ label, onChange, property, country, value }): JSX.Element => {
  const [options, setOptions] = useState<DropdownModel[]>([]);
  const [selected, setSelected] = useState<DropdownModel>(INITIAL_SELECTED);
  const { allByCountryName } = useStateService();

  useEffect(() => {
    setOptions(allByCountryName(country));
  }, [allByCountryName, country]);

  useEffect(() => {
    if (options.length === 0) return setSelected(INITIAL_SELECTED);
    if (!value) return;
    const existsOption = options.find((option) => option.label === value);
    if (!existsOption) return setSelected(INITIAL_SELECTED);
    setSelected(existsOption);
  }, [options, value, country]);

  const clearHandler = () => {
    setSelected(INITIAL_SELECTED);
    onChange(property, "");
  };

  return (
    <StateInputStyle
      disablePortal
      clearText=""
      value={selected.id ? selected : null}
      getOptionLabel={(option) => {
        const current: DropdownModel = option as DropdownModel;
        return current.label;
      }}
      renderOption={(props, option) => {
        const model = option as DropdownModel;
        return (
          <li {...props} key={model.id}>
            {model.label}
          </li>
        );
      }}
      options={options}
      onChange={(e, value, react) => {
        if (react === "clear") return clearHandler();
        const model = value as DropdownModel;
        if (!model) return;
        return onChange(property, model.label);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default StateInput;
