import { Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useStoreContext } from "../../../hooks/Contexts";
import { ContainerMaxWidth } from "../../../contexts/Store";
import ReachedMaxMintedStyle from "./Style";
import ButtonBlue from "../../form/button-blue/Component";

const ReachedMaxMinted: FC = () => {
  const { setStore } = useStoreContext();

  const getStarted = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          component: prevState.flowControl.steps.getStarted.component,
          title: "Get Started",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  };

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          component: prevState.flowControl.steps.getStarted.component,
          title: "Get Started",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  return (
    <ReachedMaxMintedStyle>
      <Typography variant="textUppercase" textAlign="center">
        You have reached the maximum numbers of Trump Digital Trading Cards that can be purchased by
        a wallet.
        <br />
        <br />
        <ButtonBlue
          props={{
            variant: "contained",
            fullWidth: true,
          }}
          onClick={getStarted}
        >
          Start again
        </ButtonBlue>
      </Typography>
    </ReachedMaxMintedStyle>
  );
};

export default ReachedMaxMinted;
