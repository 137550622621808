import { BigNumber } from "@ethersproject/bignumber";
import VerifyUserDTO from "../dtos/verifyUserDTO";
import VerifyUserModel from "../models/verifyUser";
import { ethers } from "ethers";

const verifyUserMapper = (dto: VerifyUserDTO): VerifyUserModel => ({
  prices: {
    original: +ethers.formatEther(BigNumber.from(dto.originalPrice).toString()),
    taxAmount: +ethers.formatEther(BigNumber.from(dto.taxAmount).toString()),
    total: +ethers.formatEther(BigNumber.from(dto.totalPrice).toString()),
    shipping: +ethers.formatEther(BigNumber.from(dto.shippingPrice).toString()),
    contractPrice: +ethers.formatEther(BigNumber.from(dto.contractPrice).toString()),
  },
  dto,
});

export { verifyUserMapper };
