import { Container, styled } from '@mui/material';
import theme from '../../utils/theme';

const StepperStyle = styled(Container)(() => ({
  minHeight: "calc(100% - 53px)",
  backgroundColor: theme.palette.color1.main,
  paddingTop: "72px",
  paddingBottom: "30px",
  '.content': {
    paddingTop: '50px',
    [theme.breakpoints.down("xxs")]: {
      paddingTop: "15px"
    }
  },
  '.hidden': {
    display: 'none',
  },
  '.banner-grid-item': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default StepperStyle;
