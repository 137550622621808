import { useCallback } from 'react';
import countryMapper from '../mappers/countryMapper';
import DropdownModel from '../models/dropdown';
import iso31662 from 'iso-3166-2';

const useCountryService = () => {
  const all = useCallback((): DropdownModel[] => {
    const countries = iso31662.data;

    const countryNames = Object.values(countries)
      .map((dto) => dto.name)
      .sort((a, b) => a.localeCompare(b));

    return countryNames.map((name, index) =>
      countryMapper.toModel({ name, index })
    );
  }, []);

  const getCodeByName = useCallback((name: string): string | undefined => {
    if (!name) return undefined;
    const country = iso31662.country(name);
    if (!country) {
      return undefined;
    }

    return country.code;
  }, []);

  return { all, getCodeByName };
};

export default useCountryService;
