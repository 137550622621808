import { FC, useEffect, useState } from "react";
import KycFormStyle from "./Style";
import { Alert, Grid, InputAdornment, Typography } from "@mui/material";
import TextInput from "../../form/text-input/Component";
import { ContainerMaxWidth, KYCForm } from "../../../contexts/Store";
import { INITIAL_FORM } from "./StaticData";
import { INITIAL_FORM as INITIAL_SHIPPING_FORM } from "../shipping-address-form/StaticData";
import { useStoreContext } from "../../../hooks/Contexts";
import { emailValidation, isValid } from "../../../utils/validation";
import ButtonBlue from "../../form/button-blue/Component";
import { ExploreOutlined, Mail } from "@mui/icons-material";
import { hasPhysicalItem } from "../../../utils/utils";

const KycForm: FC = (): JSX.Element => {
  const [kycForm, setKycForm] = useState<KYCForm>(INITIAL_FORM);
  const [completed, setCompleted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const { setStore, store } = useStoreContext();

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          ...prevState.flowControl.steps.offers,
          title: "Checkout",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    setKycForm((prevState) => ({
      ...prevState,
      email: store.cart.kycForm.email ? store.cart.kycForm.email : store.cart.user.email,
      postalCode: store.cart.kycForm.postalCode ? store.cart.kycForm.postalCode : "",
    }));
  }, [store.cart.user.email]);

  const onChange = (property: string, value: string): void => {
    setKycForm((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  useEffect(() => {
    setCompleted(
      isValid(INITIAL_FORM, kycForm, [
        "email",
        "emailConfirmation",
        "postalCode",
      ])
    );
  }, [kycForm]);

  const save = () => {
    if (!completed) {
      setFormError("Please fill in the required fields.");
      return;
    }
    if (!emailValidation(kycForm.email)) {
      setFormError("The email address provided is invalid.");
      return;
    }
    if (kycForm.email !== kycForm.emailConfirmation) {
      setFormError(
        "The confirmation email address does not match the email address."
      );
      return;
    }
    setFormError("");
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          ...prevState.flowControl.steps.kyc,
          title: "Checkout",
        },
      },
      cart: {
        ...prevState.cart,
        kycForm,
        addresses: {
          ...prevState.cart.addresses,
          shipping: {
            ...INITIAL_SHIPPING_FORM,
            postalCode:
              store.cart.offer && hasPhysicalItem(store.cart.offer)
                ? kycForm.postalCode
                : "",
          },
        },
      },
    }));
  };

  return (
    <KycFormStyle>
      <Grid container>
        <Grid item xxxs={12}>
          <Typography variant="h1">
            Buy your Trump Digital <br /> Trading Cards
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ my: 3 }}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="textRegularNormal">
            Please enter your information below:
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="Email"
            property="email"
            value={kycForm.email}
            onChange={onChange}
            props={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              },
              autoFocus: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="Confirm Email Address"
            property="emailConfirmation"
            value={kycForm.emailConfirmation}
            onChange={onChange}
            props={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="ZIP code / Postal Code"
            property="postalCode"
            value={kycForm.postalCode}
            onChange={onChange}
            props={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <ExploreOutlined />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
      </Grid>
      {formError && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xxxs={12}>
            <Alert severity="error">{formError}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <ButtonBlue
            props={{
              variant: "contained",
              fullWidth: true,
            }}
            onClick={save}
          >
            Next
          </ButtonBlue>
        </Grid>
      </Grid>
    </KycFormStyle>
  );
};

export default KycForm;
